<template>
  <van-cell-group class="cell-group" @click="showDetail">
    <van-cell title="缴费周期">
      <template #right-icon>
        <span class="address"
          >{{ formatDate(item.startTime) }}~{{ formatDate(item.endTime) }}</span
        >
      </template>
    </van-cell>
    <van-cell title="缴费金额">
      <template #right-icon>
        <span class="price-month"
          >{{ Number(item.totalCost).toFixed(2) }}元</span
        >
      </template>
    </van-cell>
    <van-cell title="缴费单号">
      <template #right-icon>
        <span class="price-time">{{ item.paymentNo || "无" }}</span>
      </template>
    </van-cell>
    <van-cell title="缴费时间">
      <template #right-icon>
        <span class="price">{{ item.paymentTime }}</span>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script>
export default {
  name: "RecordItem",
  props: {
    item: Object,
  },
  computed: {
    // 获取住址
    getLocation() {
      return (obj) => {
        return `${obj.estateName}${obj.buildingName}${obj.unitName}${obj.roomName}`;
      };
    },
    // 格式化时间
    formatDate() {
      return (date) => {
        let arr = date.split("-");
        return `${arr[0]}年${arr[1]}月`;
      };
    },
  },
  methods: {
    // 查看记录详情
    showDetail() {
      const { id } = this.item;
      this.$router.push("/paymentRecordsDetail?id=" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-group {
  margin-top: 10px;
}
</style>