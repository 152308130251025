<template>
  <div class="payment-records">
    <van-field
      readonly
      clickable
      name="datetimePicker"
      :value="value"
      label="时间选择"
      placeholder="点击选择时间"
      @click="showPicker = true"
    />
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        :formatter="formatter"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- 物业费记录 -->
    <template v-if="list.length > 0">
      <record-item
        v-for="item in list"
        :key="item.id"
        :item="item"
      ></record-item>
    </template>

    <div v-else class="noData">暂无记录!</div>
  </div>
</template>

<script>
import RecordItem from "./RecordItem.vue";

import { getPayList } from "@/api/propertyApi";

export default {
  name: "PayMentRecords",
  components: {
    RecordItem,
  },
  data() {
    return {
      value: `${new Date().getFullYear()}-${new Date().getMonth() + 1}`,
      showPicker: false,
      minDate: new Date(new Date().getFullYear(), 0, 1),
      maxDate: new Date(new Date().getFullYear() + 10, 0, 1),
      currentDate: new Date(),
      list: [],
    };
  },
  created() {
    this.getRecordList();
  },
  methods: {
    // 获取记录列表
    async getRecordList() {
      const { value } = this;
      // const { roomId } = this.$route.query;
      let that = this;
      const res = await getPayList({
        // roomId,
        openId: that.$store.state.user.openId,
        payMonth: value,
      });

      if (res.code == 200) {
        this.list = res.data;
      }
    },
    // 格式化时间
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    // 选择日期
    onConfirm(val) {
      this.showPicker = false;
      const date = new Date(val);
      this.value = `${date.getFullYear()}-${date.getMonth() + 1}`;
      // 重新获取列表
      this.getRecordList();
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-records {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;

  .noData {
    text-align: center;
    color: #ddd;
    font-size: 14px;
    margin-top: 20px;
  }
}
</style>