import axios from '@/utils/axios';
const baseURL = '/api'

// 获取缴费记录
export const getPayList = (params) => {
  return axios({
    baseURL,
    url: '/property/propertyexpenses/getPaymentRecord',
    method: 'get',
    params
  });
};

// 获取缴费详情
export const getPayDetail = (params) => {
  return axios({
    baseURL,
    url: '/property/propertyexpenses/detail',
    method: 'get',
    params
  });
};

// 缴费
export const pay = (params, data) => {
  return axios({
    baseURL,
    url: '/payment/pay',
    method: 'post',
    params,
    data
  });
};

// 获取物业费缴费列表
export const getList = (params) => {
  return axios({
    baseURL,
    url: '/property/propertyexpenses/getPropertyListWx',
    method: 'get',
    params
  });
};